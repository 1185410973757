@import url('//fonts.googleapis.com/css?family=PT+Sans:400,700,400italic,700italic&subset=latin,cyrillic-ext');
/**
 * COLORS
 */
/**
 * GRID
 */
/**
 * SPERMS
 */
/**
 * PROJECTS ICONS
 */
@font-face {
  font-family: 'DINPro';
  src: url('../fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/DINPro-Bold.otf') format('opentype'), url('../fonts/DINPro-Bold.woff') format('woff'), url('../fonts/DINPro-Bold.ttf') format('truetype'), url('../fonts/DINPro-Bold.svg#DINPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal; }

html {
  min-height: 100%;
  min-width: 1190px;
  position: relative; }

body {
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  margin-bottom: 125px;
  width: 100%; }

/**
 * TAGS
 */
a, a:visited, a:active {
  color: #1172AD;
  text-decoration: underline; }
  a:hover, a:visited:hover, a:active:hover {
    color: #1172AD;
    text-decoration: none; }

h1, .h1, h2, .h2, h3, .h3 {
  line-height: 1.1em; }

h1, .h1 {
  font-family: 'DINPro', 'PT Sans', sans-serif;
  font-size: 48px;
  margin-bottom: 15px; }
  h1.about-title, .h1.about-title {
    display: inline-block;
    vertical-align: middle; }

h2, .h2 {
  font-weight: 400;
  font-size: 26px;
  margin: 0 0 45px; }
  h2.about-subtitle, .h2.about-subtitle {
    margin-bottom: 15px; }

h3, .h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px; }

p, ul li {
  margin-bottom: 15px; }

ul li {
  list-style-image: url('../images/bullet.png'); }

/**
 * TOOLS
 */
.container {
  position: relative;
  width: 1190px !important; }

[class*="col-"], .row, .container {
  position: relative; }

.block {
  margin: 15px 0; }
  .block--padded {
    padding: 30px 0; }
  .block--grey {
    background: url('../images/textures/izyskaniya_tx-01.png') center no-repeat; }
  .block__title {
    margin-top: 0; }

.columnized {
  column-count: 2;
  column-gap: 30px;
  margin-bottom: 15px; }
  .columnized p:first-child {
    font-size: 16px; }

.centered {
  text-align: center; }

.narrow {
  margin: auto;
  width: 66%; }

.img-inline {
  display: block;
  margin-bottom: 5px; }
  .img-inline--left {
    float: left;
    margin-right: 15px; }
  .img-inline--right {
    float: right;
    margin-left: 15px; }

/**
 * CIRCLES'&'WAVES
 */
.cw-container {
  font-size: 0;
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden; }
  .cw-container--compl .cw-container__item:before, .cw-container--compl .cw-container__item:after {
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 16px;
    top: 40px;
    width: 16px; }
  .cw-container--compl-purp .cw-container__item:first-child:before {
    background-image: url('../images/cw/purp/start.png'); }
  .cw-container--compl-purp .cw-container__item:nth-child(1):last-child:after {
    background-image: url('../images/cw/purp/finish_1.png'); }
  .cw-container--compl-purp .cw-container__item:nth-child(2):last-child:after {
    background-image: url('../images/cw/purp/finish_2.png'); }
  .cw-container--compl-purp .cw-container__item:nth-child(3):last-child:after {
    background-image: url('../images/cw/purp/finish_3.png'); }
  .cw-container--compl-purp .cw-container__item:nth-child(4):last-child:after {
    background-image: url('../images/cw/purp/finish_4.png'); }
  .cw-container--compl-green .cw-container__item:first-child:before {
    background-image: url('../images/cw/green/start.png'); }
  .cw-container--compl-green .cw-container__item:nth-child(1):last-child:after {
    background-image: url('../images/cw/green/finish_1.png'); }
  .cw-container--compl-green .cw-container__item:nth-child(2):last-child:after {
    background-image: url('../images/cw/green/finish_2.png'); }
  .cw-container--compl-green .cw-container__item:nth-child(3):last-child:after {
    background-image: url('../images/cw/green/finish_3.png'); }
  .cw-container--compl-green .cw-container__item:nth-child(4):last-child:after {
    background-image: url('../images/cw/green/finish_4.png'); }
  .cw-container--compl-orange .cw-container__item:first-child:before {
    background-image: url('../images/cw/orange/start.png'); }
  .cw-container--compl-orange .cw-container__item:nth-child(1):last-child:after {
    background-image: url('../images/cw/orange/finish_1.png'); }
  .cw-container--compl-orange .cw-container__item:nth-child(2):last-child:after {
    background-image: url('../images/cw/orange/finish_2.png'); }
  .cw-container--compl-orange .cw-container__item:nth-child(3):last-child:after {
    background-image: url('../images/cw/orange/finish_3.png'); }
  .cw-container--compl-orange .cw-container__item:nth-child(4):last-child:after {
    background-image: url('../images/cw/orange/finish_4.png'); }
  .cw-container--compl-blue .cw-container__item:first-child:before {
    background-image: url('../images/cw/blue/start.png'); }
  .cw-container--compl-blue .cw-container__item:nth-child(1):last-child:after {
    background-image: url('../images/cw/blue/finish_1.png'); }
  .cw-container--compl-blue .cw-container__item:nth-child(2):last-child:after {
    background-image: url('../images/cw/blue/finish_2.png'); }
  .cw-container--compl-blue .cw-container__item:nth-child(3):last-child:after {
    background-image: url('../images/cw/blue/finish_3.png'); }
  .cw-container--compl-blue .cw-container__item:nth-child(4):last-child:after {
    background-image: url('../images/cw/blue/finish_4.png'); }
  .cw-container--compl-left {
    text-align: left; }
    .cw-container--compl-left .cw-container__item:last-child:after {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%, #ffffff 100%) !important;
      height: 100%;
      width: 60px;
      top: 0; }
  .cw-container--compl-right {
    text-align: left; }
    .cw-container--compl-right .cw-container__item:first-child:before {
      background-image: linear-gradient(to right, #ffffff 0%, #ffffff 1%, rgba(255, 255, 255, 0) 100%) !important;
      height: 100%;
      width: 60px;
      top: 0; }
  .cw-container__item {
    display: inline-block;
    font-size: 14px;
    position: relative;
    vertical-align: top;
    width: 25%; }
    .cw-container__item:before, .cw-container__item:after {
      content: "";
      display: none;
      position: absolute;
      z-index: 10; }
    .cw-container__item:before {
      left: 0; }
    .cw-container__item:after {
      right: 0; }
    .cw-container__item-image {
      overflow: hidden;
      height: 80px;
      margin-bottom: 15px;
      position: relative; }
      .cw-container__item-image:before, .cw-container__item-image:after {
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .cw-container__item-image-i {
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2; }
    .cw-container__item:nth-child(1) .cw-container__item-image--purp:before {
      background-image: url('../images/cw/purp/wave_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--purp:after {
      background-image: url('../images/cw/purp/circle_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--green:before {
      background-image: url('../images/cw/green/wave_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--green:after {
      background-image: url('../images/cw/green/circle_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--orange:before {
      background-image: url('../images/cw/orange/wave_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--orange:after {
      background-image: url('../images/cw/orange/circle_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--blue:before {
      background-image: url('../images/cw/blue/wave_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(1) .cw-container__item-image--blue:after {
      background-image: url('../images/cw/blue/circle_1.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--purp:before {
      background-image: url('../images/cw/purp/wave_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--purp:after {
      background-image: url('../images/cw/purp/circle_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--green:before {
      background-image: url('../images/cw/green/wave_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--green:after {
      background-image: url('../images/cw/green/circle_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--orange:before {
      background-image: url('../images/cw/orange/wave_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--orange:after {
      background-image: url('../images/cw/orange/circle_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--blue:before {
      background-image: url('../images/cw/blue/wave_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(2) .cw-container__item-image--blue:after {
      background-image: url('../images/cw/blue/circle_2.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--purp:before {
      background-image: url('../images/cw/purp/wave_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--purp:after {
      background-image: url('../images/cw/purp/circle_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--green:before {
      background-image: url('../images/cw/green/wave_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--green:after {
      background-image: url('../images/cw/green/circle_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--orange:before {
      background-image: url('../images/cw/orange/wave_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--orange:after {
      background-image: url('../images/cw/orange/circle_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--blue:before {
      background-image: url('../images/cw/blue/wave_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(3) .cw-container__item-image--blue:after {
      background-image: url('../images/cw/blue/circle_3.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--purp:before {
      background-image: url('../images/cw/purp/wave_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--purp:after {
      background-image: url('../images/cw/purp/circle_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--green:before {
      background-image: url('../images/cw/green/wave_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--green:after {
      background-image: url('../images/cw/green/circle_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--orange:before {
      background-image: url('../images/cw/orange/wave_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--orange:after {
      background-image: url('../images/cw/orange/circle_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--blue:before {
      background-image: url('../images/cw/blue/wave_4.png');
      z-index: 1; }
    .cw-container__item:nth-child(4) .cw-container__item-image--blue:after {
      background-image: url('../images/cw/blue/circle_4.png');
      z-index: 1; }
  .cw-container__descr {
    padding: 0 45px 0;
    position: relative;
    text-align: left;
    z-index: 10; }
    .cw-container__descr strong {
      font-size: 24px;
      left: 22.5px;
      position: absolute;
      top: 0; }

/**
 * HEADER
 */
.header {
  margin-bottom: 30px;
  overflow: hidden;
  padding-top: 30px;
  position: relative;
  transition: opacity .2s ease-out;
  z-index: 100; }
  .header__logo {
    background: url('../images/logo_black.png') center no-repeat;
    display: block;
    float: left;
    height: 81px;
    width: 234px; }
  .header__nav {
    align-items: flex-start;
    display: flex;
    float: left;
    margin-right: -234px;
    padding-left: 30px;
    padding-right: 234px;
    padding-top: 25px;
    width: 100%; }
    .header__nav-item {
      color: #000 !important;
      display: block;
      font-weight: 700;
      max-width: 16.6667%;
      position: relative;
      padding: 0 10px 3px;
      text-decoration: none !important;
      text-transform: uppercase; }
      .header__nav-item span {
        position: relative; }
      .header__nav-item:hover span:after, .header__nav-item.active span:after {
        background: #000;
        bottom: -3px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        right: 0; }
      .header__nav-item:first-child {
        padding-left: 0; }
        .header__nav-item:first-child:hover span:after, .header__nav-item:first-child.active span:after {
          left: 0; }
      .header__nav-item:last-child {
        padding-right: 0; }
        .header__nav-item:last-child:hover span:after, .header__nav-item:last-child.active span:after {
          right: 0; }
  .header--main {
    left: 0;
    min-width: 1190px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 499; }
    .header--main .header__logo {
      background-image: url('../images/logo_white.png'); }
    .header--main .header__nav-item {
      color: #fff !important; }
      .header--main .header__nav-item:hover span:after, .header--main .header__nav-item.active span:after {
        background: #fff !important; }
  .header--main-fade {
    visible: invisible;
    opacity: 0; }
  .header--main-black .header__logo {
    background-image: url('../images/logo_black.png'); }
  .header--main-black .header__nav-item {
    color: #000 !important; }
    .header--main-black .header__nav-item:hover span:after, .header--main-black .header__nav-item.active span:after {
      background: #000 !important; }

/**
 * MAIN TEASER
 */
.main-teaser {
  background-position: center;
  background-size: cover;
  height: 350px;
  margin-bottom: 15px;
  position: relative; }
  .main-teaser:after {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .main-teaser--tx-1:after {
    background: url('../images/textures/vr2.png'); }
  .main-teaser--tx-2:after {
    background: url('../images/textures/hr2.png'); }
  .main-teaser--tx-3:after {
    background: url('../images/textures/vr2.png'); }
  .main-teaser--tx-4:after {
    background: url('../images/textures/hr2.png'); }
  .main-teaser--tx-5:after {
    background: url('../images/textures/vr2.png'); }
  .main-teaser--tx-6:after {
    background: url('../images/textures/hr2.png'); }

.main--contacts {
  height: 100%;
  padding: 145px 0 250px;
  position: absolute;
  top: 0;
  width: 100%; }
.main__sub-nav {
  font-size: 0;
  margin: 30px 0;
  margin-left: -15px; }
  .main__sub-nav--about {
    display: inline-block;
    margin: 0 0 0 30px;
    vertical-align: middle; }
    .main__sub-nav--about .main__sub-nav-item.active:first-child {
      margin-left: 0; }
  .main__sub-nav-item {
    border: 3px solid transparent;
    color: #000 !important;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
    padding: 5px 10px;
    text-decoration: none !important;
    text-transform: uppercase;
    vertical-align: middle; }
    .main__sub-nav-item:hover, .main__sub-nav-item.active {
      border-color: #000; }
    .main__sub-nav-item.active:first-child {
      margin-left: 15px; }

/**
 * MAIN FULLPAGE
 */
.main-fp {
  color: #fff !important; }
  .main-fp__contacts {
    color: #000 !important;
    height: 100%;
    left: 0;
    padding: 150px 30px 250px;
    position: absolute;
    top: 0;
    text-align: left !important;
    width: 100%; }
  .main-fp__anchors {
    top: 100px;
    left: 0;
    position: relative;
    text-align: center;
    width: 100%; }
    .main-fp__anchors-item {
      color: #fff !important;
      display: inline-block;
      text-decoration: none !important; }
      .main-fp__anchors-item .img-responsive {
        border: 3px solid #fff;
        margin: 0 auto 15px; }
      .main-fp__anchors-item span {
        font-size: 16px;
        display: block;
        text-transform: uppercase; }
  .main-fp__section {
    filter: grayscale(100%);
    overflow: hidden; }
    .main-fp__section--colorized {
      filter: grayscale(0%);
      transition-property: filter, -webkit-filter;
      transition-duration: 1s;
      transition-timing-function: ease-out; }
    .main-fp__section-inner {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .main-fp__section:after, .main-fp__section:before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .main-fp__section:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0; }
    .main-fp__section:after {
      z-index: 1; }
    .main-fp__section:nth-child(odd):after {
      background-image: url('../images/textures/vr2.png'); }
    .main-fp__section:nth-child(even):after {
      background-image: url('../images/textures/hr2.png'); }
    .main-fp__section:nth-child(1):before {
      background-image: url('../images/bgs/main_01.jpg'); }
    .main-fp__section:nth-child(2):before {
      background-image: url('../images/bgs/izyskaniya_01.jpg'); }
    .main-fp__section:nth-child(3):before {
      background-image: url('../images/bgs/grunt_01.jpg'); }
    .main-fp__section:nth-child(4):before {
      background-image: url('../images/bgs/project_01.jpg'); }
    .main-fp__section:nth-child(5):before {
      background-image: url('../images/bgs/stroy_01.jpg'); }
    .main-fp__section:nth-child(6):before {
      background-image: url('../images/bgs/obsled-01.jpg'); }
    .main-fp__section-content {
      margin: auto;
      position: relative;
      text-align: center;
      width: 100%;
      z-index: 100; }
      .main-fp__section-content:before, .main-fp__section-content:after {
        background: url('../images/wave_white.png') center repeat-x;
        content: "";
        height: 18px;
        position: absolute;
        top: 100px;
        width: 100%; }
      .main-fp__section-content:before {
        left: -50%;
        margin-left: -298px; }
      .main-fp__section-content:after {
        right: -50%;
        margin-right: -298px; }
      .main-fp__section-content--main:before {
        margin-left: -398px; }
      .main-fp__section-content--main:after {
        margin-right: -398px; }
    .main-fp__section:nth-child(7) .main-fp__section-content:before, .main-fp__section:nth-child(7) .main-fp__section-content:after {
      content: none; }
    .main-fp__section:nth-child(7):before, .main-fp__section:nth-child(7):after {
      content: none; }
    .main-fp__section-frame {
      border: 3px solid #fff;
      font-family: 'DINPro', 'PT Sans', sans-serif;
      font-size: 54px;
      font-weight: bold;
      line-height: 1.1em;
      margin: auto;
      padding: 30px;
      position: relative;
      width: 600px; }
      .main-fp__section-frame--main {
        border: 5px solid #fff;
        padding: 60px;
        width: 800px; }
      .main-fp__section-frame:before {
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 48px;
        margin: 0 auto 30px;
        position: relative;
        width: 48px; }
    .main-fp__section:nth-child(1) .main-fp__section-frame:before {
      display: none; }
    .main-fp__section:nth-child(2) .main-fp__section-frame:before {
      background-image: url('../images/icons/main_1.png'); }
    .main-fp__section:nth-child(3) .main-fp__section-frame:before {
      background-image: url('../images/icons/main_2.png'); }
    .main-fp__section:nth-child(4) .main-fp__section-frame:before {
      background-image: url('../images/icons/main_3.png'); }
    .main-fp__section:nth-child(5) .main-fp__section-frame:before {
      background-image: url('../images/icons/main_4.png'); }
    .main-fp__section:nth-child(6) .main-fp__section-frame:before {
      background-image: url('../images/icons/main_5.png'); }
    .main-fp__section-descr {
      font-size: 18px;
      line-height: 1.3em;
      padding: 30px 0; }
    .main-fp__section-more a {
      border: 3px solid #fff;
      color: #fff;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      padding: 11.25px 18.75px;
      text-decoration: none !important;
      text-transform: uppercase; }
    .main-fp__section-next {
      bottom: 150px;
      color: #fff !important;
      font-size: 16px;
      font-weight: bold;
      left: 0;
      padding-bottom: 39px;
      position: absolute;
      text-align: center;
      text-decoration: none !important;
      text-transform: uppercase;
      width: 100%;
      z-index: 10; }
      .main-fp__section-next:after {
        background: url(../images/arrow_down_white.png) center no-repeat;
        bottom: 0;
        content: "";
        height: 24px;
        left: 50%;
        margin-left: -25px;
        margin-top: 11.25px;
        position: absolute;
        transition: bottom .1s ease-out;
        width: 51px; }
      .main-fp__section-next:hover:after {
        bottom: -15px; }

/**
 * RESEARCHES
 */
.researches {
  margin: auto;
  width: 69%; }
  .researches__title {
    font-family: 'DINPro', 'PT Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1em;
    margin-bottom: 30px; }
  .researches__item-link {
    color: #000 !important;
    display: block;
    padding: 30px;
    text-decoration: none !important; }
    .researches__item-link:hover, .researches__item-link.active {
      background: #fff; }
      .researches__item-link:hover .researches__item-image, .researches__item-link.active .researches__item-image {
        filter: grayscale(0); }
        .researches__item-link:hover .researches__item-image:after, .researches__item-link.active .researches__item-image:after {
          content: none; }
  .researches__item-image {
    background: grey;
    filter: grayscale(80%);
    height: 185px;
    margin-bottom: 22.5px;
    position: relative;
    width: 185px; }
    .researches__item-image:after {
      background: url('../images/textures/hr2.png');
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .researches__item-name {
    font-family: 'DINPro', 'PT Sans', sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.1em;
    margin-bottom: 11.25px; }

/**
 * PROJECTS
 */
.projects {
  margin-bottom: 30px; }
  .projects--blue .projects__item--hum:before {
    background-image: url('../images/icons/round_blue_hum.png'); }
  .projects--blue .projects__item--indust:before {
    background-image: url('../images/icons/round_blue_indust.png'); }
  .projects--brown .projects__item--hum:before {
    background-image: url('../images/icons/round_brown_hum.png'); }
  .projects--brown .projects__item--indust:before {
    background-image: url('../images/icons/round_brown_indust.png'); }
  .projects--ggreen .projects__item--hum:before {
    background-image: url('../images/icons/round_ggreen_hum.png'); }
  .projects--ggreen .projects__item--indust:before {
    background-image: url('../images/icons/round_ggreen_indust.png'); }
  .projects--green .projects__item--hum:before {
    background-image: url('../images/icons/round_green_hum.png'); }
  .projects--green .projects__item--indust:before {
    background-image: url('../images/icons/round_green_indust.png'); }
  .projects--orange .projects__item--hum:before {
    background-image: url('../images/icons/round_orange_hum.png'); }
  .projects--orange .projects__item--indust:before {
    background-image: url('../images/icons/round_orange_indust.png'); }
  .projects--purple .projects__item--hum:before {
    background-image: url('../images/icons/round_purple_hum.png'); }
  .projects--purple .projects__item--indust:before {
    background-image: url('../images/icons/round_purple_indust.png'); }
  .projects__item {
    padding-left: 63px;
    position: relative; }
    .projects__item:before {
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      height: 43px;
      left: 0;
      position: absolute;
      top: 0;
      width: 43px; }
    .projects__item-name, .projects__item-place, .projects__item-descr {
      margin-bottom: 5px; }
    .projects__item-name {
      font-size: 18px; }
    .projects__item-place {
      font-size: 16px; }
    .projects__item-link {
      border-bottom: 1px dashed #1172AD;
      display: inline-block;
      text-decoration: none; }
      .projects__item-link:hover {
        border-bottom-color: transparent; }

.projects-types {
  margin-bottom: 22.5px;
  text-align: center; }
  .projects-types__link {
    border-bottom: 1px dashed #1172AD;
    font-size: 20px;
    margin: 0 15px;
    text-decoration: none !important; }
    .projects-types__link:hover, .projects-types__link.active {
      border-bottom-color: transparent; }
    .projects-types__link.active {
      color: #000; }

/**
 * CAROUSEL
 */
.carousel--custom {
  padding: 30px 100px;
  position: relative; }
.carousel__arrow {
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  position: absolute;
  top: 0;
  width: 24px; }
  .carousel__arrow--left {
    background-image: url('../images/arrow_left_black.png');
    left: 0; }
  .carousel__arrow--right {
    background-image: url('../images/arrow_right_black.png');
    right: 0; }

/**
 * CLIENT
 */
.client__name {
  font-size: 18px;
  margin-bottom: 15px; }
.client__desc {
  margin-bottom: 15px; }
.client__voice {
  font-size: 16px; }
  .client__voice small {
    display: block; }

/**
 * CERTS
 */
.certs {
  font-size: 0; }
  .certs__item {
    display: inline-block;
    margin-right: 45px;
    position: relative;
    vertical-align: top; }
    .certs__item img {
      left: 0;
      position: relative;
      transition: all .1s ease-out;
      top: 0;
      z-index: 1; }
    .certs__item:after {
      background: rgba(0, 0, 0, 0.15) url('../images/textures/izyskaniya_tx-01.png') center no-repeat;
      content: "";
      height: 100%;
      left: 11.25px;
      position: absolute;
      transition: all .1s ease-out;
      top: 11.25px;
      width: 100%;
      z-index: 0; }
    .certs__item:active img {
      left: 11.25px;
      top: 11.25px; }
    .certs__item-descr {
      font-size: 14px;
      margin-top: 18.75px; }
  .certs--prizes {
    text-align: center; }
    .certs--prizes .certs__item {
      margin-right: 0; }

/**
 * DETAILS
 */
.details-links {
  background: url('../images/bg_details.png') center top repeat-y;
  padding: 15px 30px; }
  .details-links__item {
    display: block;
    padding-left: 53px;
    line-height: 43px;
    margin-top: 30px;
    position: relative; }
    .details-links__item:first-child {
      margin-top: 0; }
    .details-links__item-icon {
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      height: 43px;
      left: 0;
      position: absolute;
      top: 0;
      width: 43px; }

/**
 * CONTACTS
 */
[id*="contactsMap"] {
  margin-bottom: 30px;
  min-height: 100px; }

#contactsMapMain {
  height: 100%; }

#contactsMapContacts {
  height: 100%; }

.contacts {
  margin-bottom: 30px; }

/**
 * TABLEABLE
 */
.tableable {
  display: table; }
  .tableable__col {
    display: table-cell;
    vertical-align: middle;
    padding: 15px; }
    .tableable__col--25 {
      width: 25%; }
    .tableable__col--50 {
      width: 50%; }
    .tableable__col--75 {
      width: 75%; }
    .tableable__col--100 {
      width: 100%; }

/**
 * FOOTER
 */
.footer {
  bottom: 0;
  background: #000;
  padding: 30px 0;
  position: absolute;
  width: 100%; }
  .footer--main {
    bottom: 0;
    left: 0;
    min-width: 1190px;
    position: fixed;
    width: 100%;
    z-index: 499; }
  .footer__year {
    bottom: -26.25px;
    color: #fff;
    left: 30px;
    font-weight: bold;
    position: absolute; }
  .footer__logo {
    background: url('../images/logo_small_white.png') left center no-repeat;
    display: block;
    float: left;
    height: 57px;
    width: 117px; }
  .footer__nav {
    align-items: flex-start;
    display: flex;
    float: left;
    margin-right: -234px;
    padding-left: 30px;
    padding-right: 234px;
    padding-top: 5px;
    width: 100%; }
    .footer__nav-item {
      color: #fff !important;
      font-weight: 700;
      max-width: 16.6667%;
      position: relative;
      padding: 0 10px 3px;
      text-decoration: none !important;
      text-transform: uppercase; }
      .footer__nav-item:first-child {
        padding-left: 0; }
      .footer__nav-item:last-child {
        padding-right: 0; }

