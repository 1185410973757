html {
  min-height: 100%;
  min-width: 1190px;
  position: relative;
}

body {
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  margin-bottom: 125px;
  width: 100%;
}

/**
 * TAGS
 */

a,
a:visited,
a:active {
  color: #1172AD;
  text-decoration: underline;

  &:hover {
    color: #1172AD;
    text-decoration: none;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  line-height: 1.1em;
}

h1, .h1 {
  font-family: 'DINPro', 'PT Sans', sans-serif;
  font-size: 48px;
  margin-bottom: $gap-default;

  &.about-title {
    display: inline-block;
    vertical-align: middle;
  }
}

h2, .h2 {
  font-weight: 400;
  font-size: 26px;
  margin: 0 0 3 * $gap-default;

  &.about-subtitle {
    margin-bottom: $gap-default;
  }
}

h3, .h3 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 2 * $gap-default;
}

p,
ul li {
  margin-bottom: $gap-default;
}

ul li {
  list-style-image: url('../images/bullet.png');
}

/**
 * TOOLS
 */

.container {
  position: relative;
  width: 1190px !important;
}

[class*="col-"],
.row,
.container {
  position: relative;
}

.block {
  margin: $gap-default 0;

  &--padded {
    padding: 2 * $gap-default 0;
  }

  &--grey {
    background: url('../images/textures/izyskaniya_tx-01.png') center no-repeat;
  }

  &__title {
    margin-top: 0;
  }
}

.columnized {
  column-count: 2;
  column-gap: 2 * $gap-default;
  margin-bottom: $gap-default;

  p:first-child {
    font-size: 16px;
  }
}

.centered {
  text-align: center;
}

.narrow {
  margin: auto;
  width: 66%;
}

.img-inline {
  display: block;
  margin-bottom: 5px;

  &--left {
    float:left;margin-right:15px;
  }

  &--right {
    float:right;margin-left:15px;
  }
}

/**
 * CIRCLES'&'WAVES
 */

.cw-container {
  font-size: 0;
  margin-bottom: $gap-default;
  text-align: center;
  overflow: hidden;

  &--compl {
    .cw-container__item {
      &:before,
      &:after {
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        height: 16px;
        top: 40px;
        width: 16px;
      }
    }
  }

  @each $spermName in $sperms {
    &--compl-#{$spermName} {
      .cw-container__item {
        &:first-child:before {
          background-image: url('../images/cw/#{$spermName}/start.png');
        }

        @for $colNum from 1 to $spermNums+1 {
          &:nth-child(#{$colNum}):last-child:after {
            background-image: url('../images/cw/#{$spermName}/finish_#{$colNum}.png');
          }
        }
      }
    }
  }

  &--compl-left {
    text-align: left;
    .cw-container__item:last-child {
      &:after {
        background-image: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%,rgba(255,255,255,1) 100%) !important;
        height: 100%;
        width: 4 * $gap-default;
        top: 0;
      }
    }
  }

  &--compl-right {
    text-align: left;
    .cw-container__item:first-child {
      &:before {
        background-image: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 1%,rgba(255,255,255,0) 100%) !important;
        height: 100%;
        width: 4 * $gap-default;
        top: 0;
      }
    }
  }

  &__item {
    display: inline-block;
    font-size: 14px;
    position: relative;
    vertical-align: top;
    width: 25%;

    &:before,
    &:after {
      content: "";
      display: none;
      position: absolute;
      z-index: 10;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

    &-image {
      overflow: hidden;
      height: 80px;
      margin-bottom: $gap-default;
      position: relative;

      &:before,
      &:after {
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &-i {
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }

    @for $colNum from 1 to $spermNums+1 {
      @each $spermName in $sperms {
        &:nth-child(#{$colNum}) {
          .cw-container__item-image {
            &--#{$spermName}:before {
              background-image: url('../images/cw/#{$spermName}/wave_#{$colNum}.png');
              z-index: 1;
            }
            &--#{$spermName}:after {
              background-image: url('../images/cw/#{$spermName}/circle_#{$colNum}.png');
              z-index: 1;
            }
          }
        }
      }
    }
  }


  &__descr {
    padding: 0 3 * $gap-default 0;
    position: relative;
    text-align: left;
    z-index: 10;

    strong {
      font-size: 24px;
      left: 1.5 * $gap-default;
      position: absolute;
      top: 0;
    }
  }
}


/**
 * HEADER
 */

.header {
  margin-bottom: 2 * $gap-default;
  overflow: hidden;
  padding-top: 2 * $gap-default;
  position: relative;
  transition: opacity .2s ease-out;
  z-index: 100;

  &__logo {
    background: url('../images/logo_black.png') center no-repeat;
    display: block;
    float: left;
    height: 81px;
    width: 234px;
  }

  &__nav {
    align-items: flex-start;
    display: flex;
    float: left;
    margin-right: -234px;
    padding-left: 2 * $gap-default;
    padding-right: 234px;
    padding-top: 25px;
    width: 100%;

    &-item {
      color: $color-default !important;
      display: block;
      font-weight: 700;
      max-width: 16.6667%;
      position: relative;
      padding: 0 10px 3px;
      text-decoration: none !important;
      text-transform: uppercase;

      span {
        position: relative;
      }

      &:hover span:after,
      &.active span:after {
        background: $color-default;
        bottom: -3px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
      }

      &:first-child {
        padding-left: 0;
        &:hover span:after,
        &.active span:after {
          left: 0;
        }
      }

      &:last-child {
        padding-right: 0;
        &:hover span:after,
        &.active span:after {
          right: 0;
        }
      }
    }
  }

  &--main {
    left: 0;
    min-width: 1190px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 499;

    .header__logo {
      background-image: url('../images/logo_white.png');
    }
    .header__nav-item {
      color: $color-white !important;

      &:hover  span:after,
      &.active span:after {
        background: $color-white !important;
      }
    }
  }

  &--main-fade {
    visible: invisible;
    opacity: 0;
  }

  &--main-black {
    .header__logo {
      background-image: url('../images/logo_black.png');
    }
    .header__nav-item {
      color: $color-default !important;

      &:hover  span:after,
      &.active span:after {
        background: $color-default !important;
      }
    }
  }
}


/**
 * MAIN TEASER
 */
.main-teaser {
  background-position: center;
  background-size: cover;
  height: 350px;
  margin-bottom: $gap-default;
  position: relative;

  &:after {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--tx-1:after {
    background: url('../images/textures/vr2.png');
  }

  &--tx-2:after {
    background: url('../images/textures/hr2.png');
  }

  &--tx-3:after {
    background: url('../images/textures/vr2.png');
  }

  &--tx-4:after {
    background: url('../images/textures/hr2.png');
  }

  &--tx-5:after {
    background: url('../images/textures/vr2.png');
  }

  &--tx-6:after {
    background: url('../images/textures/hr2.png');
  }
}


.main {

  &--contacts {
    height: 100%;
    padding: 145px 0 250px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__sub-nav {
    font-size: 0;
    margin: 2 * $gap-default 0;
    margin-left: - $gap-default;

    &--about {
      display: inline-block;
      margin: 0 0 0 2 * $gap-default;
      vertical-align: middle;

      .main__sub-nav-item.active:first-child {
        margin-left: 0;
      }
    }

    &-item {
      border: 3px solid transparent;
      color: $color-default !important;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-right: 5px;
      padding: 5px 10px;
      text-decoration: none !important;
      text-transform: uppercase;
      vertical-align: middle;

      &:hover,
      &.active {
        border-color: $color-default;
      }

      &.active:first-child {
        margin-left: $gap-default;
      }
    }
  }
}


/**
 * MAIN FULLPAGE
 */
.main-fp {
  color: $color-white !important;

  &__contacts {
    color: $color-default !important;
    height: 100%;
    left: 0;
    padding: 150px 2 * $gap-default 250px;
    position: absolute;
    top: 0;
    text-align: left !important;
    width: 100%;
  }

  &__anchors {
    top: 100px;
    left: 0;
    position: relative;
    text-align: center;
    width: 100%;

    &-item {
      color: $color-white !important;
      display: inline-block;
      text-decoration: none !important;

      .img-responsive {
        border: 3px solid $color-white;
        margin: 0 auto $gap-default;;
      }

      span {
        font-size: 16px;
        display: block;
        text-transform: uppercase;
      }
    }
  }

  &__section {
    filter: grayscale(100%);
    overflow: hidden;

    &--colorized {
      filter: grayscale(0%);
      transition-property: filter, -webkit-filter;
      transition-duration: 1s;
      transition-timing-function: ease-out;
    }

    &-inner {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:after,
    &:before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;
    }

    &:after {
      z-index: 1;
    }

    &:nth-child(odd):after {
      background-image: url('../images/textures/vr2.png');
    }

    &:nth-child(even):after {
      background-image: url('../images/textures/hr2.png');
    }

    &:nth-child(1):before {
      background-image: url('../images/bgs/main_01.jpg');
    }

    &:nth-child(2):before {
      background-image: url('../images/bgs/izyskaniya_01.jpg');
    }

    &:nth-child(3):before {
      background-image: url('../images/bgs/grunt_01.jpg');
    }

    &:nth-child(4):before {
      background-image: url('../images/bgs/project_01.jpg');
    }

    &:nth-child(5):before {
      background-image: url('../images/bgs/stroy_01.jpg');
    }

    &:nth-child(6):before {
      background-image: url('../images/bgs/obsled-01.jpg');
    }

    &-content {
      margin: auto;
      position: relative;
      text-align: center;
      width: 100%;
      z-index: 100;

      &:before,
      &:after {
        background: url('../images/wave_white.png') center repeat-x;
        content: "";
        height: 18px;
        position: absolute;
        top: 100px;;
        width: 100%;
      }

      &:before {
        left: -50%;
        margin-left: -298px;
      }

      &:after {
        right: -50%;
        margin-right: -298px;
      }

      &--main {
        &:before {
          margin-left: -398px;
        }

        &:after {
          margin-right: -398px;
        }
      }
    }

    &:nth-child(7) {
      .main-fp__section-content:before,
      .main-fp__section-content:after {
        content: none;
      }
    }
    &:nth-child(7):before,
    &:nth-child(7):after {
      content: none;
    }

    &-frame {
      border: 3px solid $color-white;
      font-family: 'DINPro', 'PT Sans', sans-serif;
      font-size: 54px;
      font-weight: bold;
      line-height: 1.1em;
      margin: auto;
      padding: 2 * $gap-default;
      position: relative;
      width: 600px;

      &--main {
        border: 5px solid $color-white;
        padding: 4 * $gap-default;
        width: 800px;
      }

      &:before {
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 48px;
        margin: 0 auto 2 * $gap-default;
        position: relative;
        width: 48px;
      }
    }

    &:nth-child(1) {
      .main-fp__section-frame {
        &:before {
          display: none;
        }

        // &:after {
        //   background: url('../images/level.png') center no-repeat;
        //   content: "";
        //   height: 116px;
        //   left: 50%;
        //   margin-left: -4px;
        //   position: absolute;
        //   top: 100%;
        //   width: 9px;
        // }
      }
    }

    &:nth-child(2) {
      .main-fp__section-frame:before {
        background-image: url('../images/icons/main_1.png');
      }
    }

    &:nth-child(3) {
      .main-fp__section-frame:before {
        background-image: url('../images/icons/main_2.png');
      }
    }

    &:nth-child(4) {
      .main-fp__section-frame:before {
        background-image: url('../images/icons/main_3.png');
      }
    }

    &:nth-child(5) {
      .main-fp__section-frame:before {
        background-image: url('../images/icons/main_4.png');
      }
    }

    &:nth-child(6) {
      .main-fp__section-frame:before {
        background-image: url('../images/icons/main_5.png');
      }
    }

    &-descr {
      font-size: 18px;
      line-height: 1.3em;
      padding: 2 * $gap-default 0;
    }

    &-more {
      a {
        border: 3px solid $color-white;
        color: #fff;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        padding: 0.75 * $gap-default 1.25 * $gap-default;
        text-decoration: none !important;
        text-transform: uppercase;
      }
    }

    &-next {
      bottom: 150px;
      color: $color-white !important;
      font-size: 16px;
      font-weight: bold;
      left: 0;
      padding-bottom: 39px;
      position: absolute;
      text-align: center;
      text-decoration: none !important;
      text-transform: uppercase;
      width: 100%;
      z-index: 10;

      &:after {
        background: url(../images/arrow_down_white.png) center no-repeat;
        bottom: 0;
        content: "";
        height: 24px;
        left: 50%;
        margin-left: -25px;
        margin-top: .75 * $gap-default;
        position: absolute;
        transition: bottom .1s ease-out;
        width: 51px;
      }

      &:hover:after {
        bottom: -$gap-default;
      }
    }
  }
}


/**
 * RESEARCHES
 */
.researches {
  margin: auto;
  width: 69%;

  &__row {
    // margin: 5px 0;
  }

  &__title {
    font-family: 'DINPro', 'PT Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1em;
    margin-bottom: 2 * $gap-default;
  }

  &__item {

    &-link {
      color: $color-default !important;
      display: block;
      padding: 2 * $gap-default;
      text-decoration: none !important;

      &:hover,
      &.active {
        background: $color-white;

        .researches__item-image {
          filter: grayscale(0);

          &:after {
            content: none;
          }
        }
      }
    }

    &-image {
      background: grey;
      filter: grayscale(80%);
      height: 185px;
      margin-bottom: 1.5 * $gap-default;
      position: relative;
      width: 185px;

      &:after {
        background: url('../images/textures/hr2.png');
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &-name {
      font-family: 'DINPro', 'PT Sans', sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.1em;
      margin-bottom: .75 * $gap-default;
    }
  }
}


/**
 * PROJECTS
 */
.projects {
  margin-bottom: 2 * $gap-default;

  @each $color in $projectsColors {
    @each $type in $projectsTypes {
      &--#{$color} .projects__item--#{$type}:before {
        background-image: url('../images/icons/round_#{$color}_#{$type}.png');
      }
    }
  }

  &__item {
    padding-left: 63px;
    position: relative;

    &:before {
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      height: 43px;
      left: 0;
      position: absolute;
      top: 0;
      width: 43px;
    }

    &-name, &-place, &-descr {
      margin-bottom: 5px;
    }

    &-name {
      font-size: 18px;
    }

    &-place {
      font-size: 16px;
    }

    &-link {
      border-bottom: 1px dashed $color-blue;
      display: inline-block;
      text-decoration: none;

      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
}

.projects-types {
  margin-bottom: 1.5 * $gap-default;
  text-align: center;

  &__link {
    border-bottom: 1px dashed $color-blue;
    font-size: 20px;
    margin: 0 $gap-default;
    text-decoration: none !important;

    &:hover,
    &.active {
      border-bottom-color: transparent;
    }

    &.active {
      color: $color-default;
    }
  }
}


/**
 * CAROUSEL
 */
.carousel {
  &--custom {
    padding: 2 * $gap-default 100px;
    position: relative;
  }

  &__arrow {
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    top: 0;
    width: 24px;

    &--left {
      background-image: url('../images/arrow_left_black.png');
      left: 0;
    }

    &--right {
      background-image: url('../images/arrow_right_black.png');
      right: 0;
    }
  }
}


/**
 * CLIENT
 */
.client {

  &__name {
    font-size: 18px;
    margin-bottom: $gap-default;
  }

  &__desc {
    margin-bottom: $gap-default;
  }

  &__voice {
    font-size: 16px;

    small {
      display: block;
    }
  }
}


/**
 * CERTS
 */
.certs {
  font-size: 0;

  &__item {
    display: inline-block;
    margin-right: 3 * $gap-default;
    position: relative;
    vertical-align: top;

    img {
      left: 0;
      position: relative;
      transition: all .1s ease-out;
      top: 0;
      z-index: 1;
    }

    &:after {
      background: rgba(0,0,0,.15) url('../images/textures/izyskaniya_tx-01.png') center no-repeat;
      content: "";
      height: 100%;
      left: .75 * $gap-default;
      position: absolute;
      transition: all .1s ease-out;
      top: .75 * $gap-default;
      width: 100%;
      z-index: 0;
    }

    &:active img {
      left: .75 * $gap-default;
      top: .75 * $gap-default;
    }

    &-descr {
      font-size: 14px;
      margin-top: 1.25 * $gap-default;
    }
  }

  &--prizes {
    text-align: center;

    .certs__item {
      margin-right: 0;
    }
  }
}


/**
 * DETAILS
 */
.details-links {
  background: url('../images/bg_details.png') center top repeat-y;
  padding: $gap-default 2 * $gap-default;

  &__item {
    display: block;
    padding-left: 53px;
    line-height: 43px;
    margin-top: 2 * $gap-default;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &-icon {
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      height: 43px;
      left: 0;
      position: absolute;
      top: 0;
      width: 43px;
    }
  }
}


/**
 * CONTACTS
 */
[id*="contactsMap"] {
  margin-bottom: 2 * $gap-default;
  min-height: 100px;
}

#contactsMapMain {
  height: 100%;
}

#contactsMapContacts {
  height: 100%;
}

.contacts {
 margin-bottom: 2 * $gap-default;
}


/**
 * TABLEABLE
 */
.tableable {
  display: table;

  &__col {
    display: table-cell;
    vertical-align: middle;
    padding: $gap-default;

    @each $width in (25,50,75,100) {
      &--#{$width} {
        width: #{$width}%;
      }
    }
  }
}


/**
 * FOOTER
 */
.footer {
  bottom: 0;
  background: $color-default;
  padding: 2 * $gap-default 0;
  position: absolute;
  width: 100%;

  &--main {
    bottom: 0;
    left: 0;
    min-width: 1190px;
    position: fixed;
    width: 100%;
    z-index: 499;
  }

  &__year {
    bottom: -1.75 * $gap-default;
    color: $color-white;
    left: 2 * $gap-default;
    font-weight: bold;
    position: absolute;
  }

  &__logo {
    background: url('../images/logo_small_white.png') left center no-repeat;
    display: block;
    float: left;
    height: 57px;
    width: 117px;
  }

  &__nav {
    align-items: flex-start;
    display: flex;
    float: left;
    margin-right: -234px;
    padding-left: 2 * $gap-default;
    padding-right: 234px;
    padding-top: 5px;
    width: 100%;

    &-item {
      color: $color-white !important;
      font-weight: 700;
      max-width: 16.6667%;
      position: relative;
      padding: 0 10px 3px;
      text-decoration: none !important;
      text-transform: uppercase;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}