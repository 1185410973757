/**
 * COLORS
 */

$color-default: #000;
$color-white: #fff;
$color-blue: #1172AD;


/**
 * GRID
 */

$gap-default: 15px;


/**
 * SPERMS
 */

$sperms: ("purp", "green", "orange", "blue");
$spermNums: 4;


/**
 * PROJECTS ICONS
 */

$projectsTypes: ("hum", "indust");
$projectsColors: ("blue", "brown", "ggreen", "green", "orange", "purple");